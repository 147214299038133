import { defineStore } from "pinia"

//types
import cloneDeep from "lodash-es/cloneDeep"
import type { ICashCertificate } from "types/CashCertificate.js"
import type {
	ICustomerProfile,
	IDeliveryAddress,
	IShippingAddress,
} from "types/Customer"
import type { IDiscount } from "types/Discount"
import type { IOrderData, TDeliveryMethod } from "types/Order"
import type { IRecipe } from "types/Recipe"
import type { IShopCartItem } from "types/ShopCartItem"
import OrderStatus from "../../../../gb_modules/OrderStatus"

import { useGetLocalDeliverySettings } from "@shared/api/api"
import {
	doesDiscountApply as checkDiscount,
	constructDiscountPipe,
} from "./fnDiscounts.js"

import assign from "lodash-es/assign"

//for Vue.use making properties reactive in Vue 2.x
import type { TOrderStatusNotificationMethod } from "types/Customer"
import type { IPublicInventoryItem } from "types/InventoryItem"
import type { IPayment, TPaymentType } from "types/Payment"
import type { IReceiptData, IReceiptTaxRates } from "types/Receipt"

const { fee: localDeliveryFee, zipcodes: localDeliveryzipcodes } =
	useGetLocalDeliverySettings()

interface IOrderStore {
	taxRates: IReceiptTaxRates
	sortCategories: number[]
	freeShippingMinimum: number
	flatRateShipping: number
	millFee: number
	cart: IShopCartItem[]
	recipes: IRecipe[]
	cashCertificates: ICashCertificate[]
	discounts: IDiscount[]
	millGrains: boolean
	specialInstructions: string
	filterCategory: string
	statusNotificationMethods: TOrderStatusNotificationMethod[]
	customerProfile: ICustomerProfile
	deliveryAddress: IDeliveryAddress
	deliveryMethod: TDeliveryMethod | undefined
	shippingAddress: IShippingAddress
}

export const useOrderStore = defineStore("orders", {
	state: (): IOrderStore => ({
		taxRates: {
			grocery: 0.0225,
			general: 0.1025,
		},
		sortCategories: [
			37, 77, 80, 81, 36, 82, 17, 18, 15, 21, 23, 24, 26, 38, 44, 41, 84,
		],
		freeShippingMinimum: 69,
		flatRateShipping: 8.99,
		millFee: 1.5,
		cart: [] as IShopCartItem[],
		recipes: [] as IRecipe[],
		cashCertificates: [] as ICashCertificate[],
		discounts: [] as IDiscount[],
		millGrains: false,
		specialInstructions: "",
		filterCategory: "Clearance",
		statusNotificationMethods: ["email"],
		customerProfile: {
			firstname: "",
			lastname: "",
			email: "",
			phone: "",
		},
		deliveryAddress: {
			firstname: "",
			lastname: "",
			token: "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			zipcode: "",
			phone: "",
			deliveryInstructions: "",
		},
		deliveryMethod: undefined,
		shippingAddress: {} as IShippingAddress,
	}),
	getters: {
		getItemInCartBySKU(state) {
			return (sku: number): IShopCartItem | undefined => {
				const item = state.cart.find((i) => i.sku === sku)
				return item
			}
		},
		cartNeedsMilling(state): boolean {
			const needsMilling = state.cart.some(
				(item) => item.needs_milling === true,
			)
			return needsMilling
		},

		itemsThatNeedMilling(state): IShopCartItem[] {
			const needsMilling = state.cart.filter(
				(item) => item.needs_milling === true,
			)
			return needsMilling
		},

		/**
		 * @returns {number} The number of items in the cart that need milling
		 * */
		millCount(state): number {
			let millCount = 0
			if (state.millGrains) millCount += this.itemsThatNeedMilling.length
			state.recipes.forEach((recipe) => {
				if (recipe.millGrains) millCount++
			})
			return millCount
		},

		totalMillingFee(state): number {
			if (state.deliveryMethod === "shipment") {
				return this.millCount * state.millFee
			} else {
				return 0
			}
		},
		doesDiscountApply(state) {
			return (discount: IDiscount): boolean => {
				return checkDiscount(state.cart, discount)
			}
		},
		itemsThatWontShip: (state) => {
			return state.cart.filter((item) => item.ships === false)
		},
		itemsThatShipFree: (state): IShopCartItem[] => {
			return state.cart.filter(
				(item) => item.free_shipping_eligible === true,
			)
		},
		subtotalOfItemsThatShipFree(state): number {
			const items = this.itemsThatShipFree
			let subtotal = 0
			items.forEach((item) => {
				subtotal += item.price * item.quantity
			})
			state.recipes.forEach((recipe) => {
				recipe.items.forEach((item) => {
					subtotal += item.price * item.quantity
				})
			})
			return subtotal
		},
		itemsThatDontShipFree: (state): IShopCartItem[] => {
			return state.cart.filter(
				(item) => item.free_shipping_eligible === false,
			)
		},
		canShip(): boolean {
			if (this.itemsThatWontShip.length > 0) return false
			else return true
		},
		freeShippingEligible(state): boolean {
			if (this.subtotalOfItemsThatShipFree > state.freeShippingMinimum) {
				//Qualified Item Subtotal Above Free Minimum
				if (this.itemsThatDontShipFree.length === 0)
					return true //And All Items Ship Free
				else return false
			} else return false
		},
		flatRateShippingEligible(state): boolean {
			if (this.subtotalOfItemsThatShipFree > state.freeShippingMinimum) {
				//Qualified Item Subtotal Below Free Minimum
				if (this.itemsThatDontShipFree.length === 0)
					return true //And All Items Ship Free
				else return false
			} else return false
		},
		shippingCost(state): number {
			if (this.canShip === false)
				return 0 //Shipping Not Available so return $0
			else if (this.freeShippingEligible)
				return 0 //All Items Ship Free
			else if (this.flatRateShippingEligible)
				return state.flatRateShipping
			else if (
				this.subtotalOfItemsThatShipFree > state.freeShippingMinimum
			) {
				//Qualified Item Subtotal ABOVE Free Minimum
				let totalShippingFee = 0 //--> Shipping Fee is Sum of Shipping Fees Only
				this.itemsThatDontShipFree.forEach((item) => {
					totalShippingFee += item.shipping_fee * item.quantity
				})
				return totalShippingFee
			} else {
				//Qualified Item Subtotal BELOW Free Minimum
				let totalShippingFee = state.flatRateShipping //--> Shipping Fee is Flat Rate Plus Sum of Shipping Fees
				this.itemsThatDontShipFree.forEach((item) => {
					totalShippingFee += item.shipping_fee
				})
				return totalShippingFee
			}
		},
		finalShippingCostForSelectedDeliveryMethod(state): number {
			if (state.deliveryMethod === "pickup") return 0
			else if (state.deliveryMethod === "delivery")
				return localDeliveryFee.value
			else if (state.deliveryMethod === "shipment")
				return this.shippingCost
			else return 0
		},
		itemCount: (state): number => {
			let count = state.cart.length
			count += state.recipes.length
			return count
		},

		//RECIPE GETTERS
		getRecipeByID: (state) => {
			return (id: number): IRecipe | undefined =>
				state.recipes.find((i) => i.id === id)
		},
		getNextRecipeID: (state): number => {
			let nextID = 1
			if (state.recipes.length > 0) {
				const lastID = state.recipes[state.recipes.length - 1].id
				nextID = lastID + 1
			}
			return nextID
		},
		getItemInRecipe: (state) => {
			return (
				recipeID: number,
				sku: number,
			): IShopCartItem | undefined => {
				const recipe = state.recipes.find((i) => i.id === recipeID)
				if (recipe) return recipe.items.find((i) => i.sku === sku)
				else return undefined
			}
		},
		getQuantityOrderedElsewhere(state) {
			return (sku: number, recipeID?: number): number => {
				let quantityOrdered = 0
				//if a recipe, check the store cart
				if (recipeID) {
					const cartItem = this.getItemInCartBySKU(sku)
					if (cartItem) quantityOrdered += cartItem.quantity
				}
				state.recipes.forEach((recipe) => {
					//exclude current recipe
					if (recipe.id === recipeID) return
					const recipeItem = this.getItemInRecipe(recipe.id, sku)
					if (recipeItem) quantityOrdered += recipeItem.quantity
				})
				return quantityOrdered
			}
		},
		//Cash Certificates
		cashCertificateBalance: (state): number => {
			let balance = 0
			state.cashCertificates.forEach(
				(certificate) => (balance += certificate.currentValue),
			)
			return balance
		},
		//Gross Sales
		grossSalesGeneral: (state): number => {
			let generalSales = 0
			state.cart.forEach((item) => {
				if (!item.grocery && !item.notax) {
					generalSales += item.price * item.quantity
				}
			})
			state.recipes.forEach((recipe) => {
				recipe.items.forEach((item) => {
					if (!item.grocery && !item.notax) {
						generalSales += item.price * item.quantity
					}
				})
			})
			return generalSales
		},
		grossSalesGrocery: (state): number => {
			let grocerySales = 0
			state.cart.forEach((item) => {
				if (item.grocery) {
					grocerySales += item.price * item.quantity
				}
			})
			state.recipes.forEach((recipe) => {
				recipe.items.forEach((item) => {
					if (item.grocery) {
						grocerySales += item.price * item.quantity
					}
				})
			})
			return grocerySales
		},
		grossSalesNoTax: (state): number => {
			let noTaxSales = 0
			state.cart.forEach((item) => {
				if (item.notax) {
					noTaxSales += item.price * item.quantity
				}
			})
			state.recipes.forEach((recipe) => {
				recipe.items.forEach((item) => {
					if (item.notax) {
						noTaxSales += item.price * item.quantity
					}
				})
			})
			return noTaxSales
		},
		grossSubtotal(): number {
			return (
				this.grossSalesGeneral +
				this.grossSalesGrocery +
				this.grossSalesNoTax
			)
		},
		totalDiscount: (state): number => {
			let discount = 0
			state.cart.forEach((item) => {
				discount += item.discount * item.quantity
			})
			state.recipes.forEach((recipe) => {
				recipe.items.forEach((item) => {
					discount += item.discount * item.quantity
				})
			})
			return discount
		},
		//Net Sales
		netSalesGeneral: (state): number => {
			let generalSales = 0
			state.cart.forEach((item) => {
				if (!item.grocery && !item.notax) {
					generalSales += item.netPrice * item.quantity
				}
			})
			state.recipes.forEach((recipe) => {
				recipe.items.forEach((item) => {
					if (!item.grocery && !item.notax) {
						generalSales += item.netPrice * item.quantity
					}
				})
			})
			return generalSales
		},
		netSalesGrocery: (state): number => {
			let grocerySales = 0
			state.cart.forEach((item) => {
				if (item.grocery) {
					grocerySales += item.netPrice * item.quantity
				}
			})
			state.recipes.forEach((recipe) => {
				recipe.items.forEach((item) => {
					if (item.grocery) {
						grocerySales += item.netPrice * item.quantity
					}
				})
			})
			return grocerySales
		},
		netSalesNoTax(state): number {
			let noTaxSales = 0
			state.cart.forEach((item) => {
				if (item.notax) {
					noTaxSales += item.netPrice * item.quantity
				}
			})
			state.recipes.forEach((recipe) => {
				recipe.items.forEach((item) => {
					if (item.notax) {
						noTaxSales += item.netPrice * item.quantity
					}
				})
			})
			return noTaxSales
		},
		netSubtotal(): number {
			return (
				this.netSalesGeneral + this.netSalesGrocery + this.netSalesNoTax
			)
		},
		proportionGeneral(): number {
			return this.netSalesGeneral / this.netSubtotal
		},
		proportionGrocery(): number {
			return this.netSalesGrocery / this.netSubtotal
		},
		totalGeneralTax(state): number {
			return this.netSalesGeneral * state.taxRates.general
		},
		totalGroceryTax(state): number {
			return this.netSalesGrocery * state.taxRates.grocery
		},
		totalTax(): number {
			return this.totalGeneralTax + this.totalGroceryTax
		},
		grandTotal(state): number {
			let subGrandTotal = this.netSubtotal + this.totalTax
			if (state.deliveryMethod === "delivery")
				subGrandTotal += localDeliveryFee.value
			if (state.deliveryMethod === "shipment")
				subGrandTotal += this.shippingCost
			if (this.totalMillingFee > 0) subGrandTotal += this.totalMillingFee
			return Math.round(subGrandTotal * 100) / 100
		},
		cashCertificateBalanceUsed(): number {
			if (this.cashCertificateBalance == 0) return 0
			else if (this.cashCertificateBalance > this.grandTotal)
				return this.grandTotal
			else return this.cashCertificateBalance
		},
		balanceDue(): number {
			return this.grandTotal - this.cashCertificateBalanceUsed
		},
		getGiftCertificatePayments(state): IPayment[] {
			const payments: IPayment[] = []
			if (this.cashCertificateBalanceUsed > 0) {
				if (state.cashCertificates.length === 1) {
					const payment = {
						type: "giftcertificate" as TPaymentType,
						valueUsed: this.cashCertificateBalanceUsed,
						certificateID: state.cashCertificates[0].certificateID,
					}
					payments.push(payment)
				} else if (state.cashCertificates.length > 1) {
					state.cashCertificates.sort(
						(a, b) => a.currentValue - b.currentValue,
					)
					let remainingCertificateValueUsed =
						this.cashCertificateBalanceUsed
					for (const certificate of state.cashCertificates) {
						if (
							remainingCertificateValueUsed >
							certificate.currentValue
						) {
							const payment = {
								type: "giftcertificate" as TPaymentType,
								valueUsed: certificate.currentValue,
								certificateID: certificate.certificateID,
							}
							remainingCertificateValueUsed -=
								certificate.currentValue
							payments.push(payment)
						} else {
							const payment = {
								type: "giftcertificate" as TPaymentType,
								valueUsed: remainingCertificateValueUsed,
								certificateID: certificate.certificateID,
							}
							payments.push(payment)
							break
						}
					}
				}
			}
			return payments
		},
		getReceiptData(state): IReceiptData {
			const receipt = {
				grossSales: {
					general: this.grossSalesGeneral,
					grocery: this.grossSalesGrocery,
					noTax: this.grossSalesNoTax,
				},
				grossSalesTotal: this.grossSubtotal,

				taxRates: state.taxRates,
				tax: {
					general: this.totalGeneralTax,
					grocery: this.totalGroceryTax,
				},
				totalTax: this.totalTax,

				shipping: this.finalShippingCostForSelectedDeliveryMethod,
				millingFee: this.totalMillingFee,
				discounts: state.discounts,
				cartDiscountTotal: 0, //TODO: Needs implementing
				totalDiscount: this.totalDiscount,

				netSales: {
					general: this.netSalesGeneral,
					grocery: this.netSalesGrocery,
					noTax: this.netSalesNoTax,
				},
				netSalesTotal: this.netSubtotal,

				grandTotal: this.grandTotal,
			}
			return receipt
		},
		getCustomerProfile(state): ICustomerProfile {
			const customerProfile = {
				firstname: state.customerProfile.firstname,
				lastname: state.customerProfile.lastname,
				email: state.customerProfile.email,
				phone: state.customerProfile.phone,
			}
			return customerProfile
		},
		getOrderData(state): IOrderData {
			const orderData: IOrderData = {
				customerID: 0,
				status: OrderStatus.PAYED,
				source: "online",
				location: 1,
				items: state.cart,
				recipes: state.recipes,
				deliveryMethod: state.deliveryMethod ?? "pickup",
				statusNotificationMethods: state.statusNotificationMethods,
				needs_milling: this.cartNeedsMilling,
				millGrains: state.millGrains,
				specialInstructions: state.specialInstructions,
			}
			if (state.deliveryMethod === "delivery") {
				orderData.delivery = state.deliveryAddress
			} else if (state.deliveryMethod === "shipment") {
				orderData.shipment = state.shippingAddress
			}
			return orderData
		},
	},
	actions: {
		addCashCertificate(certificate: ICashCertificate): void {
			this.cashCertificates.push(certificate)
		},
		removeCashCertificate(certificateID: string): void {
			const index = this.cashCertificates.findIndex(
				(certificate) => certificate.certificateID === certificateID,
			)
			this.cashCertificates.splice(index, 1)
		},
		addItemToCart(item: IPublicInventoryItem, quantity): IShopCartItem {
			const shopCartItem = { ...item } as IShopCartItem
			shopCartItem.quantity = quantity
			//shopCartItem.millGrains = false currently not used so set false
			shopCartItem.netPrice = item.price
			shopCartItem.discount = 0
			return shopCartItem
		},
		updateItemInCart(
			item: IShopCartItem | IPublicInventoryItem,
			quantity: number,
		): void {
			const cartItem = this.cart.find(
				(cartItem) => cartItem.sku === item.sku,
			)
			//If Not Found, Add CartItem Props and Push to Cart
			if (cartItem === undefined) {
				const shopCartItem = { ...item } as IShopCartItem
				shopCartItem.quantity = quantity
				//shopCartItem.millGrains = false currently not used so set false
				shopCartItem.netPrice = item.price
				shopCartItem.discount = 0
				this.cart.push(shopCartItem)
				//Otherwise Update Quantity
			} else {
				//does this work?  otherwise use code below
				cartItem.quantity = quantity

				//const index = this.cart.indexOf(cartItem)
				//this.cart[index].quantity = quantity
			}
			this.updateCart()
		},
		removeItemInCart(sku: number): void {
			const index = this.cart.findIndex((i) => i.sku === sku)
			if (index >= 0) {
				this.cart.splice(index, 1)
				this.updateCart()
			} else {
				console.error(
					"[store/order/action/removeItemInCart] item not found",
				)
			}
		},
		clearCart(): void {
			try {
				this.cart = []
				this.recipes = []
				this.updateCart()
			} catch (error) {
				console.error(error)
			}
		},
		addRecipe(newRecipe: IRecipe): void {
			if (this.recipes.length === 0) {
				newRecipe.id = 1
			} else {
				const lastID = this.recipes[this.recipes.length - 1].id
				newRecipe.id = lastID + 1
			}
			this.recipes.push(newRecipe)
			this.updateCart()
		},
		updateRecipe(updatedRecipe: IRecipe): void {
			const recipeID = updatedRecipe.id
			const recipe = this.recipes.find((i) => i.id === recipeID)
			if (recipe) {
				const index = this.recipes.indexOf(recipe)
				this.recipes[index] = updatedRecipe
				this.updateCart()
			} else {
				throw new Error("Recipe not found", { cause: recipeID })
			}
		},
		removeItemInRecipe(recipeID: number, sku: number): void {
			const recipe = this.recipes.find((i) => i.id === recipeID)
			if (!recipe) return
			const recipeItem = recipe.items.find((i) => i.sku === sku)
			if (!recipeItem) return
			const index = recipe.items.indexOf(recipeItem)
			recipe.items.splice(index, 1)
			this.updateCart()
		},

		removeRecipe(recipeID: number): void {
			const recipe = this.recipes.find((i) => i.id === recipeID)
			if (!recipe) return
			const index = this.recipes.indexOf(recipe)
			this.recipes.splice(index, 1)
			this.updateCart()
		},
		addDiscount(discount: IDiscount): void {
			if (
				!this.discounts.some(
					(activeDiscount) => activeDiscount.code === discount.code,
				)
			) {
				this.discounts.push(discount)
				this.updateCart()
			}
		},
		removeDiscount(discountCode: string) {
			const index = this.discounts.findIndex(
				(discount) => discount.code === discountCode,
			)
			this.discounts.splice(index, 1)
			this.updateCart()
		},
		// Each time an item or discount is added, it could affect the netPrice
		updateCart() {
			try {
				// reset price properites for all items
				let discountedCart = [] as IShopCartItem[]
				this.cart.forEach((item) => {
					item.discount = 0
					const netPrice = item.price * 100
					item.netPrice = netPrice
					discountedCart.push(item)
				})

				this.recipes.forEach((recipe) => {
					recipe.items.forEach((item) => {
						item.discount = 0
						const netPrice = item.price * 100
						item.netPrice = netPrice
						discountedCart.push(item)
					})
				})

				for (let k = this.discounts.length - 1; k >= 0; k--) {
					if (!checkDiscount(discountedCart, this.discounts[k])) {
						this.discounts.splice(k, 1)
					}
				}

				const pipe = constructDiscountPipe(this.discounts)
				discountedCart = pipe(discountedCart)

				discountedCart.forEach((item) => {
					item.netPrice = Math.round(item.netPrice) / 100
					item.discount = Math.round(item.discount) / 100
				})
			} catch (error) {
				console.error("updateCart", error)
			}
		},
		resetOrder(state) {
			window.localStorage.removeItem("saved-order")
			Object.assign(
				state,
				setDefaultState(orderDefaults, customerDefaults),
			)
		},
	},
})

const orderDefaults = {
	cart: [],
	recipes: [],
	//order				: {},
	//receipt				: {},
	cashCertificates: [],
	discounts: [],
	millGrains: false,
	specialInstructions: "",
	filterCategory: "Clearance",
	statusNotificationMethods: ["email"],
}

const baseCustomerDefaults = {
	customer: {
		firstname: "",
		lastname: "",
		email: "",
		phone: "",
		zipcode: "",
	},

	deliveryData: {
		address: {
			address1: "",
			address2: "",
			city: "",
			state: "",
			zipcode: "",
		},
		deliveryInstructions: "",
	},

	deliveryMethod: "pickup" as TDeliveryMethod,
	shippingData: {},
}

const customerDefaults = assign({}, baseCustomerDefaults)

const setDefaultState = (orderDefaults, customerDefaults) => {
	const defaultState: IOrderStore = assign(
		{},
		orderDefaults,
		customerDefaults,
	)

	return cloneDeep(defaultState)
}
