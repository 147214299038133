import { logger as log } from "@Plugins/clientLogger"
import {
	getErrorByType,
	getErrorsByType,
	hasJRPErrors,
} from "@shared/api/JRPHelper"
import {
	NetworkError,
	RequestError,
	RequestNotFoundError,
	ServerError,
} from "@shared/errors"
import { useAccountStore } from "@website/stores/account"
import axios from "axios"

import type { AxiosError, AxiosResponse } from "axios"
import type { IRequestError, IServerError } from "types/APIResponses"
import type { IJRP } from "types/JRP"
const api = axios.create({
	baseURL: "/api/v0/public/",
})

api.interceptors.response.use(
	(response) => {
		return response
	},
	(error: AxiosError) => {
		if (error.response) {
			if (error.response.status === 401) {
				return handleAuthenticationErrors()
			} else if (error.response.status === 404) {
				return handle404RequestError(error.response)
			} else if (error.response.status === 500) {
				if (hasJRPErrors(error.response.data)) {
					const serverErrors = getErrorsByType<IServerError>(
						error.response.data as IJRP,
						"ServerError",
					)
					if (serverErrors.length > 0)
						return handleServerErrors(error, serverErrors)
					else {
						const serverErrors = createServerErrorObjectinArray()
						return handleServerErrors(error, serverErrors)
					}
				} else {
					const serverErrors = createServerErrorObjectinArray()
					return handleServerErrors(error, serverErrors)
				}
			}
			const requestErrors = getErrorsByType<IRequestError>(
				error.response.data as IJRP,
				"RequestError",
			)
			if (requestErrors.length > 0)
				return handleRequestErrors(error, requestErrors)
			else {
				return handleUnknownResponseError(error)
			}
		} else if (error.request) {
			console.log("error.request", error.request)
			//Check if Network Error
			if (
				error.code &&
				[
					"ECONNABORTED",
					"ETIMEDOUT",
					"ERR_NETWORK",
					"ENOTFOUND",
				].includes(error.code)
			) {
				return handleNetworkError(error)
			} else {
				return handleUnknownRequestError(error)
			}
		} else {
			return handleUnknownError(error)
		}
	},
)

const createServerErrorObjectinArray = (): IServerError[] => {
	const serverErrorsArray: IServerError[] = [
		{
			key: "Unknown",
			message: "Unknown Server Error",
		},
	]
	return serverErrorsArray
}

const handleAuthenticationErrors = () => {
	console.log("handleAuthenticationErrors")
	//Don't Error Log Authentication Errors
	//throw new AuthenticationError()
	const accountStore = useAccountStore()
	accountStore.logout()
	window.location.href = "/login"
}

const handleRequestErrors = (
	error: AxiosError,
	requestErrors: IRequestError[],
): void => {
	//console.log("handleRequestErrors", error, requestErrors)
	requestErrors.forEach((requestError) => {
		log.error(requestError, requestError.message)
	})

	throw new RequestError(
		requestErrors[0].key,
		requestErrors[0].message,
		requestErrors[0].params,
	)
}

const handle404RequestError = (errorResponse: AxiosResponse): void => {
	const requestError = getErrorByType<IRequestError>(
		errorResponse.data,
		"RequestNotFoundError",
	)
	throw new RequestNotFoundError(
		requestError.key,
		requestError.message,
		requestError.params,
	)
}

const handleUnknownResponseError = (error: AxiosError): void => {
	console.log("Unknown Response Error", error)
	log.error(error, error.message)
	throw new Error()
}

const handleUnknownRequestError = (error: AxiosError): void => {
	console.log("Unknown Request Error", error)
	log.error(error, error.message)
	throw new Error()
}

const handleNetworkError = (error: AxiosError): void => {
	console.log("handleNetworkError", error.message, error.code, error.config)
	//Don't Error Log Network Errors
	log.info("NETWORK ERROR:" + error.code, error.request)
	throw new NetworkError()
}

const handleServerErrors = (
	error: AxiosError,
	serverErrors: IServerError[],
): void => {
	console.log("handleServerErrors", error, serverErrors)
	const serverError = serverErrors[0] //Only 1 Server Possible Error Per Response
	log.error(error, serverError.message)
	throw new ServerError()
}
const handleUnknownError = (error: unknown): void => {
	console.log("handleUnknownError", error)
	if (error instanceof Error) log.error(error, error.message)
	else log.error(error, "Unknown Error")
	throw new Error()
}

export default api
