import { logger as $log } from "@Plugins/clientLogger"
import { fnIsPositiveNumeric } from "@shared/functions/fnUtilities"
import { useQuery } from "@tanstack/vue-query"
import { getInventory } from "@website/api/inventory"
import cloneDeep from "lodash/cloneDeep"
import { defineStore } from "pinia"
import { computed, ref } from "vue"
import { useOrderStore } from "./order"

import type { IPublicInventoryItem } from "types/InventoryItem"
import type { IRecipe } from "types/Recipe"
import type { IShopCartItem } from "types/ShopCartItem"

export const useRecipeBuilderStore = defineStore("recipeBuilder", () => {
	const orderStore = useOrderStore()
	const { data: inventory } = useQuery<IPublicInventoryItem[]>({
		queryKey: ["inventoryItems"],
		queryFn: getInventory,
	})

	const emptyRecipe: IRecipe = {
		id: 0,
		name: "",
		items: [],
		specialInstructions: "",
		millGrains: true,
	}

	const workingRecipe = ref<IRecipe>(cloneDeep(emptyRecipe))
	const isNewRecipe = computed((): boolean => {
		return workingRecipe.value.id === 0
	})
	const searchTermForLogging = ref("")

	const getInventoryItemBySKU = (sku: number): IPublicInventoryItem => {
		if (inventory.value === undefined) {
			const error = new Error("Inventory Not Loaded", {
				cause: "inventory.value is undefined",
			})
			$log.error(error, "Inventory Not Loaded")
			throw error
		}
		const item = inventory.value.find((item) => item.sku === sku)
		if (item === undefined) {
			const error = new Error("Inventory Item Not Found", {
				cause: "inventory.value is undefined",
			})
			$log.error(error, "Inventory Item Not Found", {
				sku,
			})
			throw error
		}
		return item
	}

	const getRecipeCopyByID = (id: number): IRecipe => {
		const recipe = orderStore.getRecipeByID(id)
		if (recipe === undefined) throw new Error("Recipe not found")
		const copy = cloneDeep(recipe)
		return copy
	}

	const hasUnsavedChanges = computed((): boolean => {
		if (isNewRecipe.value) {
			if (workingRecipe.value.items.length > 0) return true
			else return false
		}

		const originalRecipe = orderStore.getRecipeByID(workingRecipe.value.id)
		if (originalRecipe === undefined)
			throw new Error("Recipe not found", {
				cause: `hasUnsavedChanges ID: ${workingRecipe.value.id}`,
			})
		if (originalRecipe.items.length !== workingRecipe.value.items.length)
			return true
		const changedItems = workingRecipe.value.items.filter(
			(item) =>
				originalRecipe.items.find((i) => i.sku === item.sku) ===
				undefined,
		)
		if (changedItems.length > 0) return true
		return false
	})

	const needsMilling = computed((): boolean => {
		const needsMilling = workingRecipe.value.items.some(
			(item) => item.needs_milling === true,
		)
		return needsMilling
	})

	const itemCount = computed((): number => {
		return workingRecipe.value.items.length
	})

	const updateWorkingRecipe = (
		sku: number,
		newQuantity: number,
		resultRank: number | undefined = undefined,
		source = "RecipeBuilder",
	): {
		success: boolean
		updatedCartQuantity: number
		errorMessage: string
	} => {
		let success = false
		let errorMessage = ""
		const currentCartQuantity = getCurrentCartQuantity(sku)
		let updatedCartQuantity = currentCartQuantity

		if (currentCartQuantity !== 0 && newQuantity === 0) {
			removeItemFromWorkingRecipe(sku, source)
			success = true
			errorMessage = ""
			updatedCartQuantity = 0
			return { success, updatedCartQuantity, errorMessage }
		}

		const inventoryItem = getInventoryItemBySKU(sku)
		const availabilityResult = checkForSufficientInventory(
			inventoryItem,
			newQuantity,
			source,
		)
		const bulkResult = isValidQuantity(inventoryItem, newQuantity, source)
		if (!fnIsPositiveNumeric(newQuantity)) {
			success = false
			errorMessage = "Please Enter A Positive Number"
		} else if (availabilityResult.available === false) {
			success = false
			errorMessage = availabilityResult.errorMessage
		} else if (bulkResult.valid === false) {
			success = false
			errorMessage = bulkResult.errorMessage
		} else {
			success = true
			errorMessage = ""
			updatedCartQuantity = Math.round(newQuantity * 100) / 100
		}

		const roundedNewQuantity = Math.round(newQuantity * 100) / 100

		if (success) {
			const recipeItem = workingRecipe.value.items.find(
				(item) => item.sku === sku,
			)
			if (recipeItem === undefined) {
				addNewItemToWorkingRecipe(
					sku,
					roundedNewQuantity,
					resultRank,
					source,
				)
			} else {
				updateItemQuantityInWorkingRecipe(
					recipeItem,
					sku,
					roundedNewQuantity,
					source,
				)
			}
		}
		return { success, updatedCartQuantity, errorMessage }
	}

	const getCurrentCartQuantity = (sku: number): number => {
		const recipeItem = workingRecipe.value.items.find(
			(item) => item.sku === sku,
		)
		if (recipeItem === undefined) return 0
		else return recipeItem.quantity
	}

	const checkForSufficientInventory = (
		inventoryItem: IPublicInventoryItem,
		quantity: number,
		source: string,
	): { available: boolean; errorMessage: string } | { available: true } => {
		const itemInventory = inventoryItem?.inventory ?? 0
		const quantityOrderedElsewhere = orderStore.getQuantityOrderedElsewhere(
			inventoryItem.sku,
			workingRecipe.value.id,
		)
		const quantityAvailable = itemInventory - quantityOrderedElsewhere
		if (quantity > quantityAvailable) {
			const roundedQuantityAvailable =
				Math.round(quantityAvailable * 100) / 100
			const available = false
			const errorMessage = `Sorry, Only ${roundedQuantityAvailable} Available`
			$log.info("Recipe Builder: Insufficient Inventory", {
				recipeID: workingRecipe.value.id,
				recipeName: workingRecipe.value.name,
				sku: inventoryItem.sku,
				name: inventoryItem?.name,
				inputQuantity: quantity,
				quantityAvailable,
				source,
			})
			return { available, errorMessage }
		} else {
			return { available: true }
		}
	}

	const isValidQuantity = (
		inventoryItem: IPublicInventoryItem,
		quantity: number,
		source: string,
	): { valid: boolean; errorMessage: string } | { valid: true } => {
		if (inventoryItem.bulk === true) {
			return { valid: true }
		} else {
			if (Number.isInteger(quantity)) {
				return { valid: true }
			} else {
				const valid = false
				const errorMessage =
					"This Item Is Not Sold In Bulk, Please Enter Whole Numbers Only"
				$log.info("Recipe Builder: Invalid Quantity For Bulk Item", {
					recipeID: workingRecipe.value.id,
					recipeName: workingRecipe.value.name,
					sku: inventoryItem.sku,
					name: inventoryItem?.name,
					quantity,
					source,
				})
				return { valid, errorMessage }
			}
		}
	}

	const addNewItemToWorkingRecipe = (
		sku: number,
		quantity: number,
		resultRank: number | undefined = undefined,
		source = "RecipeBuilder",
	): void => {
		const inventoryItem = getInventoryItemBySKU(sku)
		if (inventoryItem === undefined) throw new Error("Item not found")
		$log.info("Add Item To Recipe", {
			recipeID: workingRecipe.value.id,
			recipeName: workingRecipe.value.name,
			sku,
			name: inventoryItem.name,
			quantity,
			searchTerm: searchTermForLogging.value,
			resultRank,
			source,
		})
		const newRecipeItem: IShopCartItem = {
			...inventoryItem,
			quantity: quantity,
			netPrice: inventoryItem.price,
			discount: 0,
		}
		workingRecipe.value.items.push(newRecipeItem)
	}

	const updateItemQuantityInWorkingRecipe = (
		recipeItem: IShopCartItem,
		sku: number,
		quantity: number,
		source = "RecipeBuilder",
	): void => {
		const itemIndex = workingRecipe.value.items.indexOf(recipeItem)
		$log.info("Update Item In Recipe", {
			recipeID: workingRecipe.value.id,
			recipeName: workingRecipe.value.name,
			sku,
			name: recipeItem.name,
			previousQuantity: recipeItem.quantity,
			quantity,
			source,
		})
		workingRecipe.value.items[itemIndex].quantity = quantity
	}
	const removeItemFromWorkingRecipe = (
		sku: number,
		source = "Recipe Builder",
	): void => {
		const recipeItem = workingRecipe.value.items.find((i) => i.sku === sku)
		if (recipeItem === undefined) return
		$log.info("Remove Item From Recipe", {
			recipeID: workingRecipe.value.id,
			recipeName: workingRecipe.value.name,
			sku,
			name: recipeItem.name,
			quantity: recipeItem.quantity,
			source,
		})
		const index = workingRecipe.value.items.indexOf(recipeItem)
		workingRecipe.value.items.splice(index, 1)
	}

	const clearWorkingRecipe = (): void => {
		workingRecipe.value.items = []
		$log.info("clearRecipe")
	}

	const saveWorkingRecipe = (): void => {
		if (workingRecipe.value.id === 0) {
			orderStore.addRecipe(workingRecipe.value)
			$log.info("Save New Recipe", {
				recipeID: workingRecipe.value.id,
				recipeName: workingRecipe.value.name,
			})
		} else {
			orderStore.updateRecipe(workingRecipe.value)
			$log.info("Save Updated Recipe", {
				recipeID: workingRecipe.value.id,
				recipeName: workingRecipe.value.name,
			})
		}
		resetWorkingRecipe()
	}

	const resetWorkingRecipe = (): void => {
		workingRecipe.value = cloneDeep(emptyRecipe)
	}

	return {
		workingRecipe,
		needsMilling,
		itemCount,
		isNewRecipe,
		hasUnsavedChanges,
		searchTermForLogging,
		getRecipeCopyByID,
		updateWorkingRecipe,
		removeItemFromWorkingRecipe,
		clearWorkingRecipe,
		saveWorkingRecipe,
		resetWorkingRecipe,
	}
})
