import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-184b40fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "gbDialog" }
const _hoisted_2 = { id: "gbDialog-title" }
const _hoisted_3 = {
  key: 0,
  id: "gbDialog-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gbModal = _resolveComponent("gbModal")!

  return (_openBlock(), _createBlock(_component_gbModal, {
    name: "gbDialog",
    height: "auto",
    adaptive: true,
    clickToClose: true,
    onBeforeOpen: $setup.beforeOpen
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString($setup.title), 1 /* TEXT */),
        ($setup.message)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($setup.message), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _createElementVNode("button", {
          class: "large",
          onClick: $setup.close
        }, _toDisplayString($setup.buttonText), 1 /* TEXT */)
      ])
    ]),
    _: 1 /* STABLE */
  }))
}