import { vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-846f5e34"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    id: "RecipeBuilder_SearchBox",
    class: _normalizeClass({ 'searchbox-loading': $props.isSearchLoading}),
    disabled: $props.isSearchLoading,
    type: "search",
    inputmode: "search",
    spellcheck: "false",
    autocorrect: "off",
    autocomplete: "off",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchTerm) = $event))
  }, null, 10 /* CLASS, PROPS */, _hoisted_1)), [
    [_vModelText, $setup.searchTerm]
  ])
}